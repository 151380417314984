.accordion{
    padding: 5%;
    margin: 3%;
    margin-top: 0;
    justify-content: space-between;
    align-items: center;
}
.accordion-item{
    font:rgb(255, 255, 255);
    color: rgb(7, 7, 7);
    background-color: rgb(126, 5, 126);
}
.accordion-header{
    color: rgb(0, 0, 255);
    background-color: blue;
}
.letters{
    color: rgba(2, 1, 1, 0.61)
}

